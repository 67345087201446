<template>
    <div>
        <b-card title="Create Aforo Acces">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row></b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Aforo" rules="required">
                                <h5>Aforo</h5>
                                <b-form-select size="lg" v-model="aforoSelected" :options="aforoOptions" @change="configureAforo()"
                                    :disabled="disableComponent" :state="errors.length > 0 ? false : null" autofocus />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Acces SCA" rules="required">
                                <h5>SCA Acces</h5>
                                <b-form-input size="lg" v-model="body.AccesSCA" :disabled="aforoIsSelected || disableComponent"
                                    :placeholder="placeHolder" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <b-row></b-row>
            <b-row style="margin-top: 20px">
                <b-col></b-col>
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="validationForm"
                    :hidden="hiddenComponent">
                    <feather-icon icon="UploadIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Create Aforo Acces
                </b-button>
                <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/aforoacces">
                    <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Go Back
                </b-button>
                <b-col></b-col>
            </b-row>
        </b-card>
    </div>
</template>
  
<script>
import { BCard, BForm, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BButton } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { ref } from "@vue/composition-api";
import axios from "@/libs/axios";
import { decryptString } from '@/libs/crypto'

export default {
    components: {
        BCard, BForm, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BButton, ValidationProvider, ValidationObserver,
    },
    computed: {
        aforoOptions() {
            this.aforos = this.aforos.map((t) => ({ text: t, value: t }))
            return this.aforos
        },
        configureAforo() {
            const aforo = this.aforoSelected.split(" - ");
            const idAforo = aforo[1].replace(/[()]/g, "")
            this.body.ID_Aforo = idAforo;
            this.aforoIsSelected = false;
            this.placeHolder = null;
        },
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.message();
                }
            })
        },
        async message() {
            const error = await this.onSubmit();
            if (error[0]) {
                const variant = "danger";
                this.$bvToast.toast("" + error[1], {
                    title: "Failed",
                    variant,
                    solid: true,
                });
            } else if (!error[0]) {
                this.disableComponent = true;
                this.hiddenComponent = true;
                const variant = "success";
                this.$bvToast.toast("Aforo Acces created correctly", {
                    title: `Create`,
                    variant,
                    solid: true,
                });
            }
        },
    },
    async created() {
        await axios
            .get("/aforo")
            .then((results) => {
                results.data.forEach(element => {
                    this.aforos.push(element.Aforo_name + " - (" + element.ID_Aforo + ")")
                });
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            });
    },
    mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
    data() {
        return {
            required,
            disableComponent: false,
            hiddenComponent: false,
            aforos: [],
            aforoSelected: null,
            aforoIsSelected: true,
            placeHolder: 'First select an Aforo'
        };
    },
    setup() {
        const blankAforoAccesData = {
            ID_Aforo: "",
            AccesSCA: "",
            IdUser: decryptString(localStorage.getItem('idUser')),
        };

        const body = ref(JSON.parse(JSON.stringify(blankAforoAccesData)));

        const onSubmit = async () => {
            const onError = [false, ""];
            await axios
                .post("/aforoacces", body.value)
                .then((result) => {
                    onError[0] = false;
                })
                .catch((error) => {
                    onError[0] = true;
                    onError[1] = error;
                });
            return onError;
        };

        return {
            body,
            onSubmit,
        };
    },
};
</script>
  
<style></style>
  